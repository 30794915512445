.easyABC {
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .easyABCLeft {
    flex: 1;
    text-align: justify;
    padding: 200px;
    :nth-child(1) {
      font-size: 2.5vw;
    }
    :nth-child(2) {
      width: 400px;
    }

    .customBtnCreate {
      background-color: #ff6f61;
      border-color: #ff6f61;
      color: white;
      transition: transform 0.5s;
      width: 350px;
    }
    .customBtnCreate:hover {
      background-color: white;
      color: #ff6f61;
      cursor: pointer;
      border: 1px solid #ff6f61;
      transform: scale(1.06);
    }
  }
  .easyABCRight {
    color: white;
    margin: 250px 200px;
    height: 50px;
    flex: 1;
    align-items: center;
    .contentEasyABCRight,
    .onDiscord,
    .onZoom,
    .groupFocus {
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
      margin: 10px;
      border-radius: 20px;
      border: 1px solid;
      background-color: #4158d0;
      background-image: linear-gradient(
        43deg,
        #4158d0 0%,
        #c850c0 46%,
        #ffcc70 100%
      );
      width: 350px;
      display: flex;
      flex-direction: row;
      .linkEasy {
        padding: 4px;
        width: 100px;
        border-radius: 10px;
        align-self: center;
        margin: 0 20px;
        background-color: white;
        > a {
          color: rgb(90, 217, 90);
          text-decoration: none;
          font-weight: 700;
          font-size: 1vw;
        }
      }
      .contentTagRight {
        display: flex;
        flex-direction: row;
        align-items: center;
        :nth-child(1) {
          margin-right: 10px;
        }

        .onDiscord {
          background-color: #0093e9;
          background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
        }
      }
      transition: transform 0.5s; /* Animation */
    }
    :hover {
      cursor: pointer;
      transform: scale(1.06);
    }
    .onDiscord {
      background-color: #0093e9;
      background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
    }
    .onZoom {
      background-color: #ff9a8b;
      background-image: linear-gradient(
        90deg,
        #ff9a8b 0%,
        #ff6a88 55%,
        #ff99ac 100%
      );
    }
    .groupFocusBox {
        width: 500px;
        display: flex;
        flex-direction: row;
        align-items: center;
        > p {
            margin-left: 30px;
            color:#ff6f61;
            font-weight: 700;
            font-size: 1vw;
            display: flex;
            flex-direction: row;
            text-align: center;
        }
        .groupFocus {
            width: 300px;
          background-color: #f4d03f;
          background-image: linear-gradient(132deg, #f4d03f 0%, #16a085 100%);
        }
    }
  }
}
