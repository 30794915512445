.wrapper {
  color: red;
  .header {
    width: 100%;
    height: 100vh;
    // background-color: #fa8bff;
    // background-image: linear-gradient(
    //   45deg,
    //   #fa8bff 0%,
    //   #2bd2ff 52%,
    //   #2bff88 90%
    // );
    background-image: url('../../assets/anhdep4.jpg');
    background-repeat: no-repeat;
    background-size: 10% + 100%;
    animation: bg 20s linear infinite;
    .contentPage {
      padding: 20px;
      cursor: pointer;
    
    }
    .introduceMyShelf {
      padding-top: 30px;
      .introduce {
        color: #05acff;
        font-weight: 800;
        font-size: 3vw;
        text-decoration: none;
      }
      .name {
        color: #05acff;
        font-weight: 800;
        font-size: 3vw;
        text-decoration: none;
      }

      .name {
        background: linear-gradient(
            to right,
            rgba(100, 200, 200, 1),
            rgba(100, 200, 200, 1)
          ),
          linear-gradient(
            to right,
            rgba(255, 0, 0, 1),
            rgba(255, 0, 180, 1),
            rgba(0, 100, 200, 1)
          );
        background-size: 100% 3px, 0 3px;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;
        transition: background-size 400ms;
      }

      .name:hover {
        background-size: 0 3px, 100% 3px;
      }
      .name {
        display: inline-block;
        font-size: 10vw;
        line-height: 12vw;
        color: #fff;
        width: 100%;
        text-transform: uppercase;
        transform: translateX(0%);
        -webkit-animation: byBottom 1s ease both;
        animation: byBottom 1s ease both;
        font-weight: 600;
        letter-spacing: 0.25vw;
        font-family: "Lato", sans-serif;
      }
      .description {
        color: #01688e;
        font-weight: 800;
        font-size: 3vw;
      }
    }
  }
  @keyframes bg {
    0% {
      background-position-x: 0;
    }

    100% {
      background-position-x: 100%;
    }
  }

  .aboutMe {
    color: black;
    .initialAboutMe {
      font-size: 3rem;
      padding: 3vw;
      a {
        box-shadow: inset 0 0 0 0 #54b3d6;
        color: #54b3d6;
        padding: 0 0.25rem;
        margin: 0 -0.25rem;
        transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
      }
      a:hover {
        color: #fff;
        box-shadow: inset 200px 0 0 0 #54b3d6;
      }

      /* Presentational styles */
      a {
        color: #54b3d6;
        font-family: "Poppins", sans-serif;
        font-size: 40px;
        font-weight: 700;
        line-height: 1.5;
        text-decoration: none;
      }
    }
    .contentAboutMe {
      text-align: center;
      padding: 0 60px;
    }
    .linkAboutMe {
      margin: 20px;
      button {
        margin: 10px;
      }
    }
  }
  .smart {
    display: flex;
    flex-direction: column;
    :nth-child(1) {
      margin-bottom: -4px;
      width: 30%;
    }
    :nth-child(2) {
      width: 30%;
    }
    :nth-child(3) {
      width: 30%;
    }
  }

  .connectMe {
    padding-top: 3vw;
    padding-bottom: 3vw;

    // background-color: #fe7079;
    background-image: linear-gradient(45deg, #e8e5e6 0%, #d7d7d7 52%);
    background-repeat: no-repeat;
    background-size: cover;
    height: 60vh;
    color: black;
    font-family: "Lato", sans-serif;
    text-align: -webkit-center;
    text-align: -moz-center;
    .bodyConnectMe {
      border-radius: 30px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px 0px,
        rgba(0, 0, 0, 0.22) 0px 15px 12px 0px;
      // padding: 2rem;
      padding: 2rem;
      background-color: #ffffff;
      width: 500px;
      .initialConnectMe {
        line-height: 2rem;
        font-size: 2.5vw;
        margin: 0;
        margin-bottom: 2rem;

        -webkit-font-smoothing: antialiased;
        letter-spacing: 0.2em;
        display: block;
        font-weight: 600;
        color: #1d1e22;
      }
      .imageConnectMe {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px 0px,
          rgba(0, 0, 0, 0.22) 0px 15px 12px 0px;
      }
      .nameConnect {
        padding-top: 20px;
        font-size: 20px;
      }
      .contentConnectMe {
      }
      .connectMeBtn {
        padding: 10px 0px;
        width: 200px;
        button {
          width: 200px;
        }
      }
    }
  }

  .myJourney {
    .listImage {
      .imagesCover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }
  }

  .footer {
    text-align: -webkit-center;
    text-align: -moz-center;
  }
}

.imageConnectMe {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

.imageConnectMe {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-20px); }
}

.avatarGrid {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
  >div {
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgb(252, 193, 92) -6px -2px 16px 0px;
  }
}


.avatarGrid {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
  >div {
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,  rgb(252, 193, 92)  -6px -2px 16px 0px;
  }
}

@-webkit-keyframes mover {
0% {
  transform: translateY(0);
}
100% {
  transform: translateY(-10px);
}
}

@keyframes mover {
0% { transform: translateY(0); }
100% { transform: translateY(-10px); }
}

.imageAnimation {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.imageAnimation.show {
  opacity: 1;
}